.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 150px 150px 50px;
    padding: 20px;
    border: 1px solid silver;
    background-color: #fff;
    border-radius: 10px;
}
.image{
    width: 450px;
}
.textContainer{
    text-align: left;
    margin-left: 30px;
    padding: 20px;
    border: 1px solid silver;
    border-radius: 10px;
}
.textContainer h3{
    color: #1a73e8;
    margin: 20px 0 30px;
}
.description{
    color: rgb(66, 66, 66);
    margin-bottom: 30px;
}
.category{
    font-weight: bold;
    color: rgb(66, 66, 66);
    margin-bottom: 50px;
}
.category span{
    color: orange;
    font-size: 1rem;
}
.buttonContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.price{
    display: inline-block;
    padding: 7px 16px;
    background-color: #34a853;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 1px, rgba(0, 0, 0, 0.09) 0px 2px 2px, rgba(0, 0, 0, 0.09) 0px 4px 4px, rgba(0, 0, 0, 0.09) 0px 4px 8px, rgba(0, 0, 0, 0.09) 0px 9px 16px;
}
.buttonContainer a{
    text-decoration: none;
    display: inline-block;
    padding: 7px 16px;
    background-color: #1a73e8;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 1px, rgba(0, 0, 0, 0.09) 0px 2px 2px, rgba(0, 0, 0, 0.09) 0px 4px 4px, rgba(0, 0, 0, 0.09) 0px 4px 8px, rgba(0, 0, 0, 0.09) 0px 9px 16px;
    transition: all 0.2s ease;
}
.buttonContainer a:hover{
    background-color: #0b499b;
}