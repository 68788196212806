.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 15px;
    border: 1px solid silver;
    margin-bottom: 20px;
    border-radius: 6px;
}
.productImage{
    width: 80px;
}
.data{
    text-align: left;
}
.data h3{
    color: #1a73e8;
    margin-bottom: 10px;
}
.data p{
    color: #34a853;
    font-weight: bold;
}
.quantity{
    background-color: orange;
    width: 25px;
    height: 25px;
    display: inherit;
    border-radius: 3px;
    line-height: 25px;
    color: white;
    font-weight: bold;
    text-align: center;
}
.buttonContainer button{
    background-color: #1a73e8;
    border: none;
    color: white;
    height: 30px;
    line-height: 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 30px;
    transition: all 0.2 ease;
    font-size: 1.6rem !important;
    font-weight: bold;
    margin-left: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.buttonContainer img{
    width: 20px;
}