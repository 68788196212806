.container{
    width: 250px;
    border: 1px solid silver;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    text-align: center;
    margin: 0 10px 50px;
    transition: all 0.2s ease;
}
.container:hover{
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.cardImage{
    width: 230px;
    height: 200px;
    margin: 10px;
}
.container h3{
    color: #404040;
    text-align: left;
    margin: 25px 20px 15px;
    font-size: 1.1rem;
}
.container p{
    color: #404040;
    text-align: left;
    margin: 15px 20px;
    font-size: 0.9rem;
    font-weight: 600;
}
.linkContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px 20px 20px;
}
.linkContainer a{
    text-decoration: none;
    color: #1a73e8;
    font-size: 0.95rem;
}
.buttonContainer button{
    font-size: 0.9rem;
    background-color: #1a73e8;
    border: none;
    color: #fff;
    width: 120px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.buttonContainer button:hover{
    background-color: #0b499b;
}
.counter{
    display: inline-block;
    width: 20px;
    margin-left: 5px;
    font-size: 1.2rem;
    font-weight: 700;
    color: #1a73e8;
}
.smallButton{
    width: 30px !important;
    font-size: 1.6rem !important;
    font-weight: bold;
    line-height: 30px;
}
.smallButton img{
    width: 20px;
}