.container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 150px 175px 50px;
}
.cartContainer{
    width: 70%;
}
.payment{
    width: 27%;
    background-color: white;
    border: 1px solid silver;
    border-radius: 5px;
    text-align: left;
}
.payment p{
    color: rgb(61, 61, 61);
    margin: 20px 20px 10px;
}
.payment p span{
    color: #1a73e8;
    font-weight: bold;
}
.buttonContainer{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 50px 20px 10px;
}
.checkout{
    display: inline-block;
    padding: 7px 16px;
    background-color: #34a853;
    color: #fff;
    border: none;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 1px, rgba(0, 0, 0, 0.09) 0px 2px 2px, rgba(0, 0, 0, 0.09) 0px 4px 4px, rgba(0, 0, 0, 0.09) 0px 4px 8px, rgba(0, 0, 0, 0.09) 0px 9px 16px;
}
.checkout:hover{
    background-color: #167d32;
}
.clear{
    cursor: pointer;
    color: #34a853;
    background: none;
    border: none;
    font-size: 1rem;
    transition: all 0.2s ease;
}
.clear:hover{
    color: #167d32;
}
.complete h3{
    color: #167d32;
    margin: 30px 0 50px;
    font-size: 1.2rem;
}
.complete a{
    text-decoration: none;
    display: inline-block;
    padding-left: 7px;
    margin-left: 5px;
    background-color: #1a73e8;
    color: #fff;
    height: 30px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 4px;
    width: 90px;
    /* line-height: 30px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: all 0.2s ease;
}
.complete a:hover{
    background-color: #08499d;
}